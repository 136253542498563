import { EditAndRemoveComponent } from "@src/app/modules/share/components/edit-and-remove/edit-and-remove.component";
import { GenderPipe } from "./pipes/gender.pipe";
import { FilterPipe } from "./pipes/filter.pipe";
import { LivetickerPipe } from "./pipes/liveticker.pipe";
import { CategoryPipe } from "./pipes/category.pipe";

export const componentDeclarations: any[] = [
    EditAndRemoveComponent,
    GenderPipe,
    FilterPipe,
    LivetickerPipe,
    CategoryPipe,
];

export const providerDeclarations: any[] = [
];
