import { ErrorHandler, Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { AlertService } from "@src/app/modules/share/services/alert.service";

@Injectable({
  providedIn: "root"
})
export class GlobalErrorHandlerService implements ErrorHandler {

    constructor(private alertService: AlertService) { }

    handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            // Server or connection error happened
            this.processError(error, `An error occurred when processing the request: ${error.status} ${error.statusText}: ${error.message}`);
        } else {
            // Angular error
            this.processError(error, `Unexpected error: ${error.message}`);
        }
    }

    processError(error: Error | HttpErrorResponse, str: string) {
        if (error != null) {
            console.error(error, str);
        }

        this.alertService.error("Oops, something went wrong!");
        // TODO log whole error (and short message) on logging server

    }
}
