import { Routes } from "@angular/router";

export const routes: Routes = [
    {
        path: "session",
        loadChildren: "@src/app/modules/login/login.module#LoginModule",
    },
    {
        path: "legal",
        loadChildren: "@src/app/modules/legal/legal.module#LegalModule",
    },
    {
        path: "",
        runGuardsAndResolvers: "always",
        loadChildren: "@src/app/modules/backbone/backbone.module#BackboneModule",
    }
];
