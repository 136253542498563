import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { AppRoutingModule } from "@src/app/app-routing.module";
import { ShareModule } from "@src/app/modules/share/share.module";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from "ngx-cookie-service";
import { NgcCookieConsentModule, NgcCookieConsentConfig } from "ngx-cookieconsent";

import { PrivacyPolicySettingsComponent } from "@src/app/components/privacy-policy-settings/privacy-policy-settings.component";
import { cookieAcceptedName, cookieDomain } from "./global";
import { AppComponent } from "@src/app/app.component";
import { HomeComponent } from "@src/app/components/home/home.component";
import { GlobalErrorHandlerService } from "@src/app/services/global-error-handler.service";
import { CookieInformationComponent } from "./components/cookie-information/cookie-information.component";

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: cookieDomain,
        name: cookieAcceptedName,
        expiryDays: 7,
    },
    position: "bottom",
    theme: "classic",
    palette: {
        popup: {
            background: "#fff",
            text: "#000000"
        },
        button: {
            background: "#7e90a1",
            text: "#fff"
        }
    },
    type: "opt-in",
    content: {
        href: "/legal/privacy-policy",
    },
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CookieInformationComponent,
        PrivacyPolicySettingsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        ShareModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            }
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        CookieService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private translate: TranslateService) {
        this.translate.setDefaultLang("de");
        this.translate.use("de");
    }
}
