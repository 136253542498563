import { Component, OnInit, Input } from "@angular/core";

import { CookieInformation } from "@src/app/modules/share/interfaces/cookie-information.interface";

@Component({
    selector: "app-cookie-information",
    templateUrl: "./cookie-information.component.html",
    styleUrls: ["./cookie-information.component.scss"],
})
export class CookieInformationComponent implements OnInit {
    @Input() cookieInformation: CookieInformation = null;

    constructor() { }

    ngOnInit() {
    }
}
