import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

import { CookieService } from "ngx-cookie-service";
import { cookieAcceptedName } from "@src/app/global";

@Injectable({
    providedIn: "root"
})
export class BaseInterceptor implements HttpInterceptor {

    constructor(private cookieService: CookieService) { }

    /**
     * This method implements the HttpInterceptor interface and blocks all outgoing or incoming http requests with credentials if cookies are not allowed
     * @param request the HTTPRequest
     * @param next the HTTPHandler
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.cookieService.check(cookieAcceptedName) === false) {
            request = request.clone({
                withCredentials: false
            });
            this.cookieService.deleteAll();
            return next.handle(request);
        } else {
            return next.handle(request);
        }
    }
}
