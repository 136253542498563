import { Component, OnInit } from "@angular/core";

import { CookieConsentBannerService } from "./services/cookie-consent-banner.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

    constructor(private cookieConsentBannerService: CookieConsentBannerService) { }

    ngOnInit() {
        this.cookieConsentBannerService.initBanner();
    }
}
