export enum AllowedMessageCategories {
    news = "news",
    matchEvents = "match-events",
    voting = "voting",
    stadionServices = "stadion-services",
    promotions = "promotions",
}

export class Notification {
    title: string = null;
    bodyText: string = null;
    category: AllowedMessageCategories = null;

    constructor(notificationsShape?: Partial<Notification>) {
        if (notificationsShape != null) {
            if (notificationsShape.title != null) {
                this.title = notificationsShape.title;
            }

            if (notificationsShape.bodyText != null) {
                this.bodyText = notificationsShape.bodyText;
            }

            if (notificationsShape.category != null) {
                this.category = notificationsShape.category;
            }
        }
    }
}
