import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";

declare const $: any;

@Component({
    selector: "app-edit-and-remove",
    templateUrl: "./edit-and-remove.component.html",
    styleUrls: ["./edit-and-remove.component.scss"]
})

export class EditAndRemoveComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    @Input() navigateTo = "";
    @Input() id: string = null;
    @Input() index = 0;
    @Input() modalTitle = "";
    @Input() htmlId = "";
    @Input() hideEditIcon = false;
    @Input() hideIconText = false;
    @Input() showAsInline = false;
    @Output() removeClicked = new EventEmitter<IEditAndRemoveEmit>();
    @Output() editClicked = new EventEmitter<IEditAndRemoveEmit>();

    constructor(private router: Router, private location: Location) { }

    ngOnInit() {
        this.subscription.add(this.location
            .subscribe(() => {
                $("#remove-really-modal-" + this.index + this.htmlId).modal("hide");
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * This method navigates the user to the given route that specified in the input property navigateTo.
     */
    onEdit(): void {
        if (this.navigateTo !== "") {
            this.router.navigate([this.navigateTo, this.id == null ? 0 : this.id])
                .catch(err => {
                    console.log(err);
                });
        }

        this.editClicked.emit({ id: this.id, index: this.index, htmlId: this.htmlId });
    }

    /**
     * This method emits true if the user confirmed the modal for deleting.
     */
    onRemove(): void {
        this.removeClicked.emit({ id: this.id, index: this.index, htmlId: this.htmlId });
    }
}

export interface IEditAndRemoveEmit {
    id?: any;
    index?: number;
    htmlId?: string;
    handleRemoveEmit?(emitResult: IEditAndRemoveEmit, options?: object): void;
}
