import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ToastrModule } from "ngx-toastr";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxPaginationModule } from "ngx-pagination";
import { TranslateModule } from "@ngx-translate/core";
import { componentDeclarations } from "@src/app/modules/share/share.common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimeAdapter } from "@ng-bootstrap/ng-bootstrap";

import { BaseInterceptor } from "./services/api/base-interceptor.service";
import { CookieService } from "ngx-cookie-service";
import { TimePickerAdapterService } from "./services/time-picker-adapter.service";
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
    declarations: [
        ...componentDeclarations
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
        NgMultiSelectDropDownModule.forRoot(),
        ImageCropperModule,
        NgxSpinnerModule,
        NgxPaginationModule,
        ToastrModule.forRoot({
            positionClass: "toast-bottom-left",
            maxOpened: 5,
            toastClass: "",
            iconClasses: {
                error: "alert alert-danger",
                info: "alert alert-info",
                success: "alert alert-success",
                warning: "alert alert-warning"
            },
            // individual
            closeButton: false,
            tapToDismiss: true,
            progressBar: true,
        }),
    ],
    providers: [
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseInterceptor,
            multi: true,
        },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        { provide: NgbTimeAdapter, useClass: TimePickerAdapterService },
    ],
    exports: [
        ...componentDeclarations,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
        NgMultiSelectDropDownModule,
        ImageCropperModule,
        NgxSpinnerModule,
        NgxPaginationModule,
        ToastrModule,
    ],
})
export class ShareModule { }
