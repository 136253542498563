import { Injectable } from "@angular/core";

import { CookieInformation } from "../modules/share/interfaces/cookie-information.interface";
import { CookieConsentBannerService } from "./cookie-consent-banner.service";
import { NgcCookieConsentService } from "ngx-cookieconsent";

@Injectable({
    providedIn: "root"
})
export class PrivacyPolicySettingsService {
    essentialCookies: CookieInformation[] = [];
    copyOfExternalCookies: CookieInformation[] = [];

    constructor(private cookieConsentBannerService: CookieConsentBannerService, private cookieConsentService: NgcCookieConsentService) {
        this.essentialCookies = this.cookieConsentBannerService.essentialCookies;
        // Hard copy of external cookies.
        this.copyOfExternalCookies = this.cookieConsentBannerService.externalCookies.map((value) => Object.assign({}, value));

        // Subscription logic to patch or decline all external cookies if the ngc cookie was accepted or declined.
        this.cookieConsentService.statusChange$
            .subscribe(
                ({ status }) => {
                    if (status === "allow") {
                        this.patchAllCopyOfExternalCookies(true);
                        this.patchCurrentStateToCookieConsent();
                    } else {
                        this.patchAllCopyOfExternalCookies(false);
                        this.patchCurrentStateToCookieConsent();
                    }
                }
            );
    }

    /**
     * This method patches the isAccepted property of each copyOfExternalCookies entry.
     * @param value Value that is patched to isAccepted of each copyOfExternalCookies entry.
     */
    patchAllCopyOfExternalCookies(value: boolean): void {
        this.copyOfExternalCookies.map((cookieInformation) => {
            cookieInformation.isAccepted = value;
        });
    }

    /**
     * This method patches all entries of the copyOfExternalCookies array to the cookie consent service and calls the triggerCookieChangeHandler method for each
     * element.
     */
    patchCurrentStateToCookieConsent(): void {
        this.cookieConsentBannerService.externalCookies = this.copyOfExternalCookies.map((value) => Object.assign({}, value));
        this.cookieConsentBannerService.externalCookies.forEach((value) => {
            this.triggerCookieChangeHandler(value);
        });
    }

    /**
     * This method calls the cookieChangeHandler of the given cookieInformation object, if the handler was set.
     * @param cookieInformation
     */
    private triggerCookieChangeHandler(cookieInformation: CookieInformation): void {
        if (cookieInformation.cookieChangeHandler != null) {
            cookieInformation.cookieChangeHandler(cookieInformation.id, cookieInformation.isAccepted);
        }
    }
}
