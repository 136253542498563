import { Media } from "./media.class";
import { StatisticsOfClubPlayer } from "./statistics-of-club-player.class";

export enum SmbFieldPosition {
    goal = "goal",
    defense = "defense",
    midfield = "midfield",
    offense = "offense",
    coach = "coach",
    coachingStaff = "coachingStaff",
}

export class Player {
    id: string = null;
    smbPhoto: string = null;
    smbPosition: number = null;
    smbForename: string = null;
    smbSurname: string = null;
    smbPlayerNumber: number = null;
    smbBirthday: Date = null;
    smbBirthplace: string = null;
    smbZodiacSign: string = null;
    smbSize: number = null;
    smbWeight: number = null;
    smbLastClub: string = null;
    smbMedia: Media[] = [];
    smbFieldPosition: SmbFieldPosition = null;
    smbAtClubSince: Date = null;
    smbClubStatistics: StatisticsOfClubPlayer = null;
    smbNationality: string = null;
    smbDescription: string = null;
    smbCoachTitle: string = null;
    smbCoachDescription: string = null;

    constructor(playerShape?: Partial<Player>) {
        if (playerShape != null) {
            if (playerShape.id != null) {
                this.id = playerShape.id;
            }

            if (playerShape.smbPhoto != null) {
                this.smbPhoto = playerShape.smbPhoto;
            }

            if (playerShape.smbPosition != null) {
                this.smbPosition = playerShape.smbPosition;
            }

            if (playerShape.smbForename != null) {
                this.smbForename = playerShape.smbForename;
            }

            if (playerShape.smbSurname != null) {
                this.smbSurname = playerShape.smbSurname;
            }

            if (playerShape.smbPlayerNumber != null) {
                this.smbPlayerNumber = playerShape.smbPlayerNumber;
            }

            if (playerShape.smbBirthday != null) {
                this.smbBirthday = new Date(playerShape.smbBirthday);
            }

            if (playerShape.smbBirthplace != null) {
                this.smbBirthplace = playerShape.smbBirthplace;
            }

            if (playerShape.smbZodiacSign != null) {
                this.smbZodiacSign = playerShape.smbZodiacSign;
            }

            if (playerShape.smbSize != null) {
                this.smbSize = playerShape.smbSize;
            }

            if (playerShape.smbWeight != null) {
                this.smbWeight = playerShape.smbWeight;
            }

            if (playerShape.smbLastClub != null) {
                this.smbLastClub = playerShape.smbLastClub;
            }

            if (playerShape.smbMedia != null) {
                this.smbMedia = playerShape.smbMedia;
            }

            if (playerShape.smbFieldPosition != null) {
                this.smbFieldPosition = playerShape.smbFieldPosition;
            }

            if (playerShape.smbAtClubSince != null) {
                this.smbAtClubSince = new Date(playerShape.smbAtClubSince);
            }

            if (playerShape.smbClubStatistics != null) {
                this.smbClubStatistics = playerShape.smbClubStatistics;
            }

            if (playerShape.smbNationality != null) {
                this.smbNationality = playerShape.smbNationality;
            }

            if (playerShape.smbDescription != null) {
                this.smbDescription = playerShape.smbDescription;
            }

            if (playerShape.smbCoachTitle != null) {
                this.smbCoachTitle = playerShape.smbCoachTitle;
            }

            if (playerShape.smbCoachDescription != null) {
                this.smbCoachDescription = playerShape.smbCoachDescription;
            }
        }
    }

}
