export enum SmbType {
    image = "image",
    video = "video",
    youtube = "youtube",
    celebrationVideo = "celebration-video",
}

export class Media {
    id: string = null;
    smbPosition: number = null;
    smbTitle: string = null;
    smbSubtitle: string = null;
    smbDescription: string = null;
    smbType: SmbType = null;
    smbSrcWifi: string = null;
    smbSrcCellular: string = null;

    constructor(mediaShape?: Partial<Media>) {
        if (mediaShape != null) {
            if (mediaShape.id != null) {
                this.id = mediaShape.id;
            }

            if (mediaShape.smbPosition != null) {
                this.smbPosition = mediaShape.smbPosition;
            }

            if (mediaShape.smbTitle != null) {
                this.smbTitle = mediaShape.smbTitle;
            }

            if (mediaShape.smbSubtitle != null) {
                this.smbSubtitle = mediaShape.smbSubtitle;
            }

            if (mediaShape.smbDescription != null) {
                this.smbDescription = mediaShape.smbDescription;
            }

            if (mediaShape.smbType != null) {
                switch (mediaShape.smbType) {
                    case "image":
                        this.smbType = SmbType.image;
                        break;
                    case "video":
                        this.smbType = SmbType.video;
                        break;
                    case "youtube":
                        this.smbType = SmbType.youtube;
                        break;
                    case "celebration-video":
                        this.smbType = SmbType.celebrationVideo;
                        break;
                    default:
                        this.smbType = SmbType.image;
                        break;
                }
            }

            if (mediaShape.smbSrcWifi != null) {
                this.smbSrcWifi = mediaShape.smbSrcWifi;
            }

            if (mediaShape.smbSrcCellular != null) {
                this.smbSrcCellular = mediaShape.smbSrcCellular;
            }
        }
    }
}
