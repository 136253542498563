import { Injectable } from "@angular/core";

import { NgbTimeStruct, NgbTimeAdapter } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: "root"
})
export class TimePickerAdapterService extends NgbTimeAdapter<Date> {

    fromModel(value: Date): NgbTimeStruct {
        if (value == null) {
            return null;
        }

        return {
            hour: value.getHours(),
            minute: value.getMinutes(),
            second: value.getSeconds()
        };
    }

    toModel(time: NgbTimeStruct): Date {
        if (time == null) {
            return null;
        }

        const todayWithTime = new Date();
        todayWithTime.setHours(time.hour, time.minute, time.second);
        return todayWithTime;
    }
}
