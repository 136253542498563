export enum Gender {
    male = "male",
    female = "female",
    diverse = "diverse",
}

export class User {
    id: string = null;
    forename: string = null;
    surname: string = null;
    email: string = null;
    password: string = null;
    newPassword: string = null;
    phone: string = null;
    gender: Gender = null;
    groupCodes: number[] = [];
    groupIds: string[] = [];
    departmentIds: string[] = [];
    profileImage?: string;
    organizationId: string = null;

    constructor(userShape?: Partial<User>) {
        if (userShape != null) {
            if (userShape.id != null) {
                this.id = userShape.id;
            }

            if (userShape.forename != null) {
                this.forename = userShape.forename;
            }

            if (userShape.surname != null) {
                this.surname = userShape.surname;
            }

            if (userShape.email != null) {
                this.email = userShape.email;
            }

            if (userShape.password != null) {
                this.password = userShape.password;
            }

            if (userShape.newPassword != null) {
                this.newPassword = userShape.newPassword;
            }

            if (userShape.phone != null) {
                this.phone = userShape.phone;
            }

            if (userShape.gender != null) {
                this.gender = userShape.gender;
            }

            if (userShape.groupCodes != null) {
                this.groupCodes = userShape.groupCodes;
            }

            if (userShape.groupIds != null) {
                this.groupIds = userShape.groupIds;
            }

            if (userShape.departmentIds != null) {
                this.departmentIds = userShape.departmentIds;
            }

            if (userShape.profileImage != null) {
                this.profileImage = userShape.profileImage;
            }

            if (userShape.organizationId != null) {
                this.organizationId = userShape.organizationId;
            }
        }
    }

}
