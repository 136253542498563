import { Pipe, PipeTransform } from "@angular/core";

import { AllowedMessageCategories } from "../classes/notification.class";

@Pipe({
    name: "category"
})
export class CategoryPipe implements PipeTransform {

    transform(value: AllowedMessageCategories): any {
        switch (value) {
            case AllowedMessageCategories.news:
                return "News";
            case AllowedMessageCategories.matchEvents:
                return "Match events";
            case AllowedMessageCategories.voting:
                return "Voting";
            case AllowedMessageCategories.stadionServices:
                return "Stadion Services";
            case AllowedMessageCategories.promotions:
                return "Promotions";
            default:
                return null;
        }
    }
}
