import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filter"
})
export class FilterPipe implements PipeTransform {

    transform(items: object[], searchText: string): object[] {
        if (items == null) { return []; }
        if (searchText == null) { return items; }

        searchText = searchText.toLowerCase();

        return items.filter(item =>
            Object.keys(item).some((key) => {
                return typeof item[key] === "string" && item[key].toLowerCase().includes(searchText);
            })
        );
    }
}
