import { Pipe, PipeTransform } from "@angular/core";

import { Gender } from "../classes/user.class";

@Pipe({
    name: "gender"
})
export class GenderPipe implements PipeTransform {

    transform(value: Gender): string {
        switch (value) {
            case Gender.male:
                return "male";
            case Gender.female:
                return "female";
            case Gender.diverse:
                return "diverse";
            default:
                return null;
        }
    }
}
