import { Media } from "./media.class";
import { Player } from "./player.class";
import { Survey } from "./survey.class";

export enum LivetickerType {
    general = "general", // type 0
    goal = "goal", // type 1
    dismissal = "dismissal", // type 2
    final = "final", // type 3
    kickoff = "kickoff", // type 4
    halftime = "halftime", // type 5
    var = "var", // type 6
    notAllowed = "not-allowed", // type 7
    lineup = "lineup", // type 8
    ad = "ad", // type 9
    sponsor = "sponsor", // type 10
    substitute = "substitute", // type 11
    redCard = "red-card", // type 12
    yellowCard = "yellow-card", // type 13
    yellowRedCard = "yellow-red-card", // type 14
    twitter = "twitter", // type 15
    playbackTime = "playback-time", // type 16
    injury = "injury", // type 17
    viewership = "viewership", // type 18
    waitForKickoff = "wait-for-kickoff", // type 19
    youtube = "youtube", // type 20
    statistic = "statistic", // type 21
    survey = "survey", // type 22
    news = "news", // type 23
    cornerKick = "corner-kick", // type 24
    freeKick = "free-kick", // type 25
    penaltyKick = "penalty-kick", // type 26
    bigChance = "big-chance", // type 27
    info = "info", // type 28
}

export class Liveticker {
    id: string = null;
    smbTime: Date = null;
    smbType: LivetickerType = null;
    smbLink: string = null;
    smbImage: string = null;
    smbAuthor: string = null;
    smbText: string = null;
    smbMatchId: string = null;
    smbMinuteOfPlay: number = null;
    smbAdditionalTime: number = null;
    smbHeadline: string = null;
    smbIsEventForOwnTeam: boolean = null;
    smbVideo: string = null;
    homeLivetickerStatistic: number = null;
    guestLivetickerStatistic: number = null;
    isAbsoluteStatistic: boolean = null;
    showDateTime: boolean = null;
    smbPlayerVideoId: string = null;
    smbPlayerId: string = null;
    smbMedia: Media = null;
    smbSubstituteInPlayer: Player = null;
    smbSubstituteOutPlayer: Player = null;
    smbIsMatchHighlight = false;
    smbSurvey: Survey = null;
    smbNewsId: string = null;

    constructor(livetickerShape?: Partial<Liveticker>) {
        if (livetickerShape != null) {
            if (livetickerShape.id != null) {
                this.id = livetickerShape.id;
            }

            if (livetickerShape.smbTime != null) {
                this.smbTime = new Date(livetickerShape.smbTime);
            }

            if (livetickerShape.smbType != null) {
                this.smbType = livetickerShape.smbType;
            }

            if (livetickerShape.smbLink != null) {
                this.smbLink = livetickerShape.smbLink;
            }

            if (livetickerShape.smbImage != null) {
                this.smbImage = livetickerShape.smbImage;
            }

            if (livetickerShape.smbAuthor != null) {
                this.smbAuthor = livetickerShape.smbAuthor;
            }

            if (livetickerShape.smbText != null) {
                this.smbText = livetickerShape.smbText;
            }

            if (livetickerShape.smbMatchId != null) {
                this.smbMatchId = livetickerShape.smbMatchId;
            }

            if (livetickerShape.smbMinuteOfPlay != null) {
                this.smbMinuteOfPlay = livetickerShape.smbMinuteOfPlay;
            }

            if (livetickerShape.smbAdditionalTime != null) {
                this.smbAdditionalTime = livetickerShape.smbAdditionalTime;
            }

            if (livetickerShape.smbHeadline != null) {
                this.smbHeadline = livetickerShape.smbHeadline;
            }

            if (livetickerShape.smbIsEventForOwnTeam != null) {
                this.smbIsEventForOwnTeam = livetickerShape.smbIsEventForOwnTeam;
            }

            if (livetickerShape.smbVideo != null) {
                this.smbVideo = livetickerShape.smbVideo;
            }

            if (livetickerShape.homeLivetickerStatistic != null) {
                this.homeLivetickerStatistic = livetickerShape.homeLivetickerStatistic;
            }

            if (livetickerShape.guestLivetickerStatistic != null) {
                this.guestLivetickerStatistic = livetickerShape.guestLivetickerStatistic;
            }

            if (livetickerShape.isAbsoluteStatistic != null) {
                this.isAbsoluteStatistic = livetickerShape.isAbsoluteStatistic;
            }

            if (livetickerShape.showDateTime != null) {
                this.showDateTime = livetickerShape.showDateTime;
            }

            if (livetickerShape.smbPlayerVideoId != null) {
                this.smbPlayerVideoId = livetickerShape.smbPlayerVideoId;
            }

            if (livetickerShape.smbPlayerId != null) {
                this.smbPlayerId = livetickerShape.smbPlayerId;
            }

            if (livetickerShape.smbMedia != null) {
                this.smbMedia = new Media(livetickerShape.smbMedia);
            }

            if (livetickerShape.smbSubstituteInPlayer != null) {
                this.smbSubstituteInPlayer = new Player(livetickerShape.smbSubstituteInPlayer);
            }

            if (livetickerShape.smbSubstituteOutPlayer != null) {
                this.smbSubstituteOutPlayer = new Player(livetickerShape.smbSubstituteOutPlayer);
            }

            if (livetickerShape.smbIsMatchHighlight != null) {
                this.smbIsMatchHighlight = livetickerShape.smbIsMatchHighlight;
            }

            if (livetickerShape.smbSurvey != null) {
                this.smbSurvey = livetickerShape.smbSurvey;
            }

            if (livetickerShape.smbNewsId != null) {
                this.smbNewsId = livetickerShape.smbNewsId;
            }
        }
    }

    toJSON(): Liveticker {
        return { ...this, smbImage: null };
    }
}
